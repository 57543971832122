import React from 'react';
import ReactDOM from 'react-dom';

import crossImg from '../../../assets/images/experiments/cross.png';
import checkmark from '../../../assets/images/experiments/webstore_checkmark.png';
import { AddingToCartNotification } from '../../../experiments/partnersRequests/AddingToCartNotification';
import { getCustomTranslation, isCustomAuth } from '../../../experiments/partnersRequests/utils';
import { getLimitsOnCart } from '../../landingAPI/setLimitOnCart';
import { getStore } from '../../store';

export const NotificationType = {
  ADD: 'add',
  UPDATE: 'update',
  LIMIT: 'limit',
  REMOVE: 'remove',
};

const TextForNotification = (locale) => {
  const translations = getCustomTranslation(locale);
  return {
    [NotificationType.ADD]: translations.wb_notification_added_to_cart,
    [NotificationType.UPDATE]: translations.wb_notification_updated_cart,
    [NotificationType.LIMIT]: (
      <>
        {translations.wb_notification_not_added_to_cart}
        <br />
        {translations.wb_notification_cart_limit}
      </>
    ),
    [NotificationType.REMOVE]: translations.wb_notification_remove_item,
  };
};

const imageOfNotification = {
  [NotificationType.ADD]: checkmark,
  [NotificationType.UPDATE]: checkmark,
  [NotificationType.LIMIT]: crossImg,
  [NotificationType.REMOVE]: crossImg,
};

export const isOverLimit = ({ cartPrice, itemPrice, itemQuantity }) => {
  const limitsOnCart = getLimitsOnCart();
  return (
    isCustomAuth() &&
    limitsOnCart &&
    cartPrice &&
    itemQuantity > 0 &&
    limitsOnCart[cartPrice.currency] &&
    itemPrice.amount * itemQuantity + Number(cartPrice.amount) >
    limitsOnCart[cartPrice.currency]
  );
};

export const renderCartNotification = (
  type: 'add' | 'update' | 'limit' | 'remove' = 'add',
  img,
  name?
) => {
  const store = getStore();
  const {
    landing: { locale },
  } = store.getState();

  const mountNode = document.getElementById('experiments');
  ReactDOM.render(
    <AddingToCartNotification
      img={imageOfNotification[`${type}`]}
      name={name}
      text={TextForNotification(locale)[`${type}`]}
    />,
    mountNode
  );

  const notificationAdding = document.querySelector('.exp-notification');
  notificationAdding.classList.toggle('exp-notification__active', true);
  setTimeout(
    () =>
      notificationAdding.classList.toggle('exp-notification__active', false),
    2000
  );
};

export const setCartNotification = () => {
  const store = getStore();

  const {
    landingServices: { cart },
    landing: { locale },
  } = store.getState();

  let cartItems = cart.items.map(({ name, imageUrl, sku, quantity }) => ({
    name,
    imageUrl,
    sku,
    quantity,
  }));

  cart.addListener((params) => {
    const addNewItemToCart = cartItems.length < cart.items.length;
    const changeItemQuantityFromLanding =
      cartItems.length === cart.items.length && params?.showPopup;
    if (addNewItemToCart) {
      const [addedItem] = cart.items.filter(
        (newItem) => !cartItems.some((oldItem) => oldItem.sku === newItem.sku)
      );
      const { name, imageUrl } = addedItem;
      renderCartNotification(NotificationType.ADD, imageUrl, name);
    }

    if (changeItemQuantityFromLanding) {
      const [updatedItem] = cart.items.filter(
        (newItem) =>
          !cartItems.some(
            (oldItem) =>
              oldItem.sku === newItem.sku &&
              oldItem.quantity === newItem.quantity
          )
      );
      if (updatedItem) {
        const { imageUrl } = updatedItem;
        renderCartNotification(NotificationType.UPDATE, imageUrl, null, locale);
      }
    }

    cartItems = cart.items.map(({ name, imageUrl, sku, quantity }) => ({
      name,
      imageUrl,
      sku,
      quantity,
    }));
  });
};